import { React, useState } from 'react'
import { InfiniteScroll, ErrorBlock } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import '../Record/record.css'
import { getWithdraw } from '../../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sleep } from 'antd-mobile/es/utils/sleep'
export default function Withdraws() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [noData, setNoData] = useState(false)
  async function mockRequest() {
    setNoData(false)
    const res = await getWithdraw({ page: pageNum })
    let list = data
    setData(list.concat(res.data))
    if (res.data.length < 1 && pageNum == 1) {//一条数据都没有
      setNoData(true)
      setData([])
      setPageNum(1)
      return []
    }
    await sleep(2000)
    setPageNum(pageNum + 1)
    return res.data
  }
  async function loadMore() {
    const append = await mockRequest()
    setHasMore(append.length >= 10)
  }
  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t('t4')}</span>
          </>
        ) : (
          <span>--- {t('t25')} ---</span>
        )}
      </>
    )
  }
  return (
    <div className='funds'>
      <div className='header'>
        <div className='backIcon' onClick={() => {
          var url = "https://www.tiikapi.com/User";
          if (window.android && typeof window.android.goCustomerService === 'function') {
            navigate('/User', { replace: true })
            window.android.goCustomerService(url);
          } else {
            console.log("android is not available.");
          }
        }}>
          <LeftOutline />
        </div>
        <p>{t('g9')}</p>
      </div>
      <div style={{ height: '50px' }}></div>
      {noData && <ErrorBlock
        title={t('t25')}
        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
        style={{
          '--image-height': '150px',
        }}
        description={<span></span>}
      >
      </ErrorBlock>}
      {!noData && <div>
        <div className='detail_box'>
          {data.map((item, index) => (
            <div key={index}>
              <div className='detail_list'>
                <div>
                  <p>{t('d6')}</p>
                  <p>{item.status == 1 ? t('mx7') : item.status == 2 ? t('d2') : t('mx6')}</p>
                </div>
                <p style={{ color: item.type == 2 ? '#ff5353' : '#0d8e31' }}>{item.amount}</p>
                <div>
                  <p>{t('mx4')}</p>
                  <p>{item.create_at}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
          <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
      </div>}
    </div>
  )
}
