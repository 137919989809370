import { React, useState, useEffect } from 'react'
import Tabber from '../copmonets/Tabber'
import { Toast, Picker, FloatingBubble, Modal } from 'antd-mobile'
import { RightOutline, MessageFill, CloseCircleOutline, ExclamationCircleFill } from 'antd-mobile-icons'
import './user.css'
import { getInfo, getService } from '../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Error from "../views/Error"
import axios from 'axios'

import {
    AppOutline,
    UnorderedListOutline,
    CheckShieldOutline,
    TeamOutline,
    UserOutline,
} from 'antd-mobile-icons'
export default function User() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)//选择器显示
    const [defaultHead, setDefaultHead] = useState('/assets/index/headDefault1.svg')
    const [userInfo, setUserInfo] = useState({})
    const [orderMoney, setOrderMoney] = useState()
    const [show, setShow] = useState(false)
    const [badge, setBadge] = useState('')
    const [openids, setOpenids] = useState('')
    const [severUrls, setSeverUrls] = useState('')
    const [iframe, setIframe] = useState(false)
    const [token2, setToken2] = useState(localStorage.getItem('token2'))
    const [errorType, setErrorType] = useState(true)
    const [list1, setlist1] = useState([
        [
            { label: '中文', value: 'zh' },
            { label: 'English', value: 'en' },
            { label: 'Español', value: 'es' },
            { label: 'Polski', value: 'pl' },
            { label: 'čeština', value: 'cs' },
            { label: 'Dansk', value: 'da' },
            { label: 'Ελληνικά', value: 'el' },
            { label: 'Română', value: 'ro' },
            { label: 'Slovenský', value: 'sk' },
            { label: 'Deutsch', value: 'de' },
            { label: 'Italiano', value: 'it' },
            { label: 'やまと', value: 'jp' },
            { label: 'Türkçe', value: 'tr' },
            { label: 'العربية', value: 'ar' },
            { label: 'Nederlands', value: 'nl' },
            { label: 'বাংল', value: 'bn' },
            { label: '한국인', value: 'kr' },
            { label: 'русский язык', value: 'ru' },
        ],
    ])

    const getData = async () => {
        try {
            Toast.show({
                icon: 'loading',
                content: t('t4'),
                duration: 1000
            });
            const res = await getInfo({});
            if (res.code === 1) {
                setDefaultHead(`/assets/index/headDefault${res.data.headimg}.svg`);
                setUserInfo(res.data);
                if (res.data.freeze_gec !== '0.00') {
                    setOrderMoney((Number(res.data.balances) - Number(res.data.freeze_gec)).toFixed(2));
                } else {
                    setOrderMoney(Number(res.data.balances));
                }
                if (res.data.openid !== '') {
                    setOpenids(res.data.openid);
                    setTimeout(() => {
                        gettest(res.data.openid);
                    }, 10);
                }
            } else {
                setErrorType(false)
                Toast.show({
                    icon: 'fail',
                    content: res.info,
                });
            }
        } catch (error) {
            console.log(error);
            setErrorType(false)
        }
    }
    const getUrl = async () => {
        try {
            const res = await getService({});
            setSeverUrls(res.data);
        } catch (error) {
            setErrorType(false)
        }
    }
    const jump = (val) => {
        navigate(val)
    }
    const outLogin = () => {
        localStorage.removeItem('token');
        Toast.show({
            icon: 'loading',
            content: t('t4'),
            duration: 800
        })
        setTimeout(() => {
            navigate('/Register')
        }, 1000);
    }
    useEffect(() => {
        getData()
        getUrl()
    }, [])
    const getKf = () => {
        if (severUrls) {
            setIframe(true)
        }
    }
    const gettest = (val) => {
        let timer = setInterval(() => {
            setIntervalService(val);
        }, 5000)
        localStorage.setItem('time', timer)
        return () => {
            clearInterval(timer);
            timer = null
        };
    }
    const setIntervalService = (val) => {
        if (val != '') {
            axios({
                url: "https://www.let-talks.cc/api/mobile/getNoRead",
                params: {
                    openid: val
                }
            }).then(res => {
                if (res.data.status == 200) {
                    setBadge(res.data.data.noRead)
                } else {
                    setBadge(0)
                }
            }).catch(err => {
                setErrorType(false)
            })
        }
    }
    const handleCloseWebView = () => {
        localStorage.removeItem('token2')
        localStorage.removeItem('password')
        setToken2()
        getData()
    };
    const switchAccount = () => {
        navigate('/Login')
    }
    useEffect(() => {
        return () => {
            clearInterval(localStorage.getItem('time'))
            localStorage.removeItem('time');
        }
    }, [])
    return (
        <div>
            {
                !errorType && <Error></Error>
            }
            {
                errorType && <div className='user'>
                    <div className='user_top'>
                        <div className='user_info'>
                            <img src={defaultHead} alt="" />
                            <div className='info_text'>
                                <p>{userInfo.nickname}</p>
                                <div className='user_id'>
                                    <p>Invite ID:{userInfo.invite}</p>
                                    <img src="/assets/index/copy.svg" alt="" onClick={() => {
                                        const textField = document.createElement('textarea');
                                        textField.value = userInfo.invite;
                                        document.body.appendChild(textField);
                                        textField.select();
                                        document.execCommand('copy');
                                        textField.remove();
                                        Toast.show({
                                            icon: 'success',
                                            content: t('t2'),
                                        })
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='user_box'>
                        <div className='user_fun'>
                            <div className='money_num'>
                                <div className='user_usdts'>
                                    <img src="/assets/index/smalllogo.svg" alt="" />
                                    <p>{orderMoney}</p>
                                    <p>USDT</p>
                                </div>
                                <img src="/assets/index/frash1.svg" alt="" onClick={() => {
                                    getData()
                                }} />
                            </div>
                            <div className='user_but'>
                                <p className='but_click but1' onClick={() => {
                                    if (userInfo.uget_address_trc != '') {
                                        navigate(`/Withdraw`)
                                    } else {
                                        Toast.show({
                                            icon: 'loading',
                                            content: t('t10'),
                                            duration: 4200
                                        })
                                        setTimeout(() => {
                                            navigate('/UserInfo')
                                        }, 4000)
                                    }
                                }}>{t('g1')}</p>
                                <p className='but_click but2' onClick={() => {
                                    navigate('/UserPay')
                                }}>{t('g2')}</p>
                            </div>
                        </div>
                        <div className='user_list'>
                            <div className='user_list_item' onClick={() => { jump('/Order') }}>
                                <div className='list_item_name'>
                                    <UnorderedListOutline />
                                    <p>{t('n2')}</p>
                                </div>
                                <RightOutline />
                            </div>
                            <div className='user_list_item' onClick={() => { jump('/Trade') }}>
                                <div className='list_item_name'>
                                    <CheckShieldOutline />
                                    <p>{t('n3')}</p>
                                </div>
                                <RightOutline />
                            </div>
                            <div className='user_list_item' onClick={() => { jump('/Team') }}>
                                <div className='list_item_name'>
                                    <TeamOutline />
                                    <p>{t('n4')}</p>
                                </div>
                                <RightOutline />
                            </div>
                            <div className='user_list_item' onClick={() => { jump('/UserInfo') }}>
                                <div className='list_item_name'>
                                    <img src="/assets/index/edit.svg" alt="" />
                                    <p>{t('g6')}</p>
                                </div>
                                <RightOutline />
                            </div>
                            <div className='user_list_item' onClick={() => { navigate('/Funds') }}>
                                <div className='list_item_name'>
                                    <img src="/assets/index/jifenmingxi.svg" alt="" />
                                    <p>{t('g7')}</p>
                                </div>
                                <RightOutline />
                            </div>
                            <div className='user_list_item' onClick={() => { navigate('/Recharge') }}>
                                <div className='list_item_name'>
                                    <img src="/assets/index/huiyuanchongzhi.svg" alt="" />
                                    <p>{t('g8')}</p>
                                </div>
                                <RightOutline />
                            </div>
                            <div className='user_list_item' onClick={() => { navigate('/Withdraws') }}>
                                <div className='list_item_name'>
                                    <img src="/assets/index/tixian.svg" alt="" />
                                    <p>{t('g9')}</p>
                                </div>
                                <RightOutline />
                            </div>
                            <div className='user_list_item' onClick={() => { setVisible(true) }}>
                                <div className='list_item_name'>
                                    <img src="/assets/index/ziranyuyanchuli.svg" alt="" />
                                    <p>{t('g10')}</p>
                                </div>
                                <RightOutline />
                            </div>
                        </div>
                        {!token2 && <div className='switch_Account' onClick={() => { switchAccount() }}>
                            <p>{t('g14')}</p>
                        </div>}
                        {token2 && <div className='Logout' onClick={() => { handleCloseWebView() }}>
                            <p>{t('g14')}</p>
                        </div>}

                        <div style={{ height: "50px" }}></div>
                    </div>
                    {
                        show && <div className='modelWindow'>
                            <div className='window_center'>
                                <div className='outLogin'>
                                    <p>{t('g12')}?</p>
                                    <div className='outLogin_fun'>
                                        <p onClick={() => { setShow(false) }}>{t('ty2')}</p>
                                        <p onClick={outLogin}>{t('ty1')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        iframe && <div className='mask'>
                            <CloseCircleOutline className='iconClose' onClick={() => { setIframe(false) }} />
                            <iframe
                                className='iframes'
                                title="Webview"
                                src={severUrls}
                            />
                        </div>
                    }
                    <Picker
                        columns={list1}
                        defaultValue={[localStorage.getItem('lang') || 'en']}
                        cancelText={t('ty2')}
                        confirmText={t('ty1')}
                        visible={visible}
                        onClose={() => {
                            setVisible(false)
                        }}
                        onConfirm={v => {
                            i18n.changeLanguage(v[0])
                            localStorage.setItem('lang', v[0])
                        }}
                    />
                    <FloatingBubble
                        onClick={() => { getKf() }}
                        axis='xy'
                        magnetic='x'
                        style={{
                            '--initial-position-bottom': '50px',
                            '--initial-position-right': '20px',
                            '--edge-distance': '24px',
                            '--background': '#f73869'
                        }}
                    >
                        {badge != 0 && <p className='setBadge' >{badge > 100 ? '99+' : badge}</p>}
                        <MessageFill fontSize={32} />
                    </FloatingBubble>
                </div >
            }
        </div>
    )
}
